import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Button, Typography, IconButton, TextField } from '@mui/material';
import api from '../../api/Api_1_3';
import Api from '../../api/Api';
import { useAuthContext } from "../../hooks/useAuthContext";
import CheckIcon from '@mui/icons-material/Check';
import GetAppIcon from '@mui/icons-material/GetApp'; // Importing the download icon
import { DataGridHeaderStyle } from '../../components/dataGridStyles/DataGridHeaderStyle';
import { StripedDataGrid } from '../../components/ui/StripedGrid';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Urls } from "../../api/Urls";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import MyDPFullDate from '../../components/ui/MyDPFullDate';
import MzErrorText from "../../components/ui/MzErrorText";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import * as Yup from 'yup'
import { Add, Close, Upload } from '@mui/icons-material';
import { colors } from '../../theme/Colors';
import * as XLSX from "xlsx";

const CustomersManagement = () => {
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const [customers, setCustomers] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [didUpdateExpiration, setDidUpdateExpiration] = useState(false);
    const [openUpdateExpiration, setOpenUpdateExpiration] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const pageNumbers = [50, 100];
    const [perPage, setPerPage] = useState(pageNumbers[1]);
    const minDate = new Date();
    const [filter, setFilter] = useState({ first_name: '', last_name: '', email: '', phone: '' }); // Add filter state

    const [tabIndex, setTabIndex] = useState(0);
    const access_token = user.access_token;

    useEffect(() => {
        getCustomers();
    }, [tabIndex, currentPage, perPage]);

    const getCustomers = async () => {
        const params = {
            // Exclude empty_profiles from params
            empty_profiles: tabIndex === 0 ? 0 : 1,
            ...(filter.first_name && { first_name: filter.first_name }),
            ...(filter.last_name && { last_name: filter.last_name }),
            ...(filter.email && { email: filter.email }),
            ...(filter.phone && { phone: filter.phone }),
            page: currentPage, // Use currentPage for pagination
            perPage: perPage,
        };
        try {
            const response = await api.get('/admin/customers', {
                headers: {
                    Authorization: `Bearer ${access_token}`
                },
                params: params
            });
            setCustomers(response.data.data);
            setTotalPages(response.data.meta.total); // Ensure this is set correctly

        } catch (error) {
            console.error('Error fetching customers:', error);
        }
    };

    const handlePageChange = (page) => {
      if (page >= 1 && page <= totalPages) {
        setCurrentPage(page); // Update currentPage to trigger data fetch
      }
    };

    const handleCreateEmptyProfiles = () => {
        navigate('/admin/create-empty-profiles');
    };

    const handleCreateCustomer = () => {
        navigate('/admin/create-customer');
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleSelectionModelChange = (newSelection) => {
        setSelectedIds(newSelection);
    };

    const handleExport = async () => {
        if (selectedIds.length === 0) {
            toast.info("Please select at least one customer to export.");
            return;
        }

        try {
            const response = await api.get('/admin/customers/export-qr-codes', {
                headers: {
                    'Authorization': `Bearer ${access_token}`,
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
                params: { individual_ids: selectedIds }, // Move params here
                responseType: 'blob' // Set response type to blob
            });

            // Create a Blob from the response data
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);

            // Create a link element and trigger a download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Customers.xlsx'); // Set the file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Clean up

        } catch (error) {
            toast.error(error.response.data.message)
        }
    };

    const handleOpenUpdateExpiration = () => {
        if (selectedIds.length === 0) {
          toast.error("Aucune ligne séléctionnée");
          return;
        }
        setOpenUpdateExpiration(true);
    };

    const handleCloseUpdateExpiration = () => setOpenUpdateExpiration(false);

    //initialValues update expiration date
    const initialValuesUpdateExpirationDate = {
      expiration_date: "",
    };

    //validation schema update expiration date
    const validationSchemaUpdateExpirationDate = Yup.object({
      expiration_date: Yup.date(),
    });

    const updateExpirationDate = (values) => {
        Api.patch(
          Urls.UPDATE_EXPIRATION_DATE,
          {
            user_ids: selectedIds,
            expiration_date: values.expiration_date,
          },
          {
            headers: {
              "Content-type": "application/json",
              authorization: `Bearer ${access_token}`,
            },
          }
        )
          .then((response) => {
            // console.log("RESPONSE UPDATE EXPIRATION DATE", response);
    
            if (response.status === 200) {
              // console.log(response);
              toast.success("Date d'expiration des cartes mise à jour");
              setDidUpdateExpiration(!didUpdateExpiration);
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              localStorage.removeItem("user");
              navigate("/login", { replace: true });
              navigate(0, { replace: true });
            }
            // console.log("RESPONSE", error.response.data.message);
            if (error?.response?.status === 403) {
              toast.error("Une erreur est survenue");
            } else {
              toast.error(error?.response?.data?.message);
            }
          });
    };

    // Add a function to handle filter changes
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value });
    };

    // Add a function to handle filter submission
    const handleFilterSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission
        getCustomers(); // Fetch customers with the current filters
    };

    const commonColumns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'status_card', headerName: 'Status', width: 150 },
        {
            field: 'is_exported',
            headerName: 'Exporté',
            width: 150,
            renderCell: (params) => (
                params.value ? <CheckIcon sx={{color: colors.mjGreenOutline}} /> : <Close sx={{color: colors.mjRedOutline}} />
            )
        }
    ];

    const filledProfileColumns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'first_name',
            headerName: 'Prénom',
            width: 150,
            renderCell: (params) => (
                <Button onClick={() => navigate(`/admin/gestion-des-customers/${params.row.id}`)}>
                    {params.value}
                </Button>
            )
        },
        {
            field: 'last_name',
            headerName: 'Nom',
            width: 150,
            renderCell: (params) => (
                <Button onClick={() => navigate(`/admin/gestion-des-customers/${params.row.id}`)}>
                    {params.value}
                </Button>
            )
        },
        { field: 'email', headerName: 'Email', width: 200 },
        { field: 'phone_number', headerName: 'Téléphone', width: 150 },
        { field: 'department', headerName: 'Dépatment', width: 150 },
        { field: 'function', headerName: 'Fonction', width: 150 },
        { field: 'expiration_date', headerName: "Date d'expiration", width: 150 },
        { field: 'status_card', headerName: 'Status', width: 150 },
        {
            field: 'url_vcf_file',
            headerName: 'Fichier VCF',
            width: 150,
            renderCell: (params) => (
                <a href={params.value} target="_blank" rel="noopener noreferrer">
                   <GetAppIcon />
                </a>
            )
        }
        // Add more columns as needed
    ];

    const columns = tabIndex === 0 ? filledProfileColumns : commonColumns ;

    const handleExportData = (jsonToExport) => {
        let jsonToExportRealValues = [];
    
        jsonToExport.map((j) => {
          const row = { Nom: j["last_name"], Prénom: j["first_name"], "Code QR": j["url_qrcode"], "Lien du profil": `${j["url_profile"]}` };
          jsonToExportRealValues.push(row);
        });
    
        // console.log("REAL VALUES", jsonToExportRealValues);
    
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(jsonToExportRealValues);
    
        XLSX.utils.book_append_sheet(workbook, worksheet, "data");
    
        XLSX.writeFile(workbook, "file.xlsx");
    
        jsonToExportRealValues = [];
    };

    const [ondeliteNext, setOnDeletNext] = useState(false);
    const [ondelite, setOnDelete] = useState(false);


    const deleteUser = () => {
      Api.delete(Urls.DELETE_USER, {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
        data: {
          user_ids: selectedIds,
          passphrase: "0zFgtYmLv9"
        },
      })
        .then((res) => {
          toast.success("utilisateur supprimé");
          setOnDeletNext(false);
          getCustomers()
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.removeItem("user");
            navigate("/login", { replace: true });
            navigate(0, { replace: true });
          }
          // console.log("RESPONSE", error.response.data.message);
          if (error?.response?.status === 403) {
            toast.error("Une erreur est survenue");
          } else {
            toast.error(error?.response?.data?.message);
          }
        });
    };

    return (
        <div>
            <ToastContainer style={{ top: "5rem" }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
            <Typography mb={3} variant='h5'>Gestion des customers</Typography>
            <Box display="flex" justifyContent="flex-end" mt={2}>
                {tabIndex === 0 && (
                    <Box display={'flex'} flexDirection={'column'} sx={{width:'100%'}} mb={2} justifyContent={'flex-start'}>
                        <form onSubmit={handleFilterSubmit} style={{ display: 'flex', gap: '16px', marginBottom: '16px' }}>
                                <TextField
                                    name="first_name"
                                    label="Prénom"
                                    variant="outlined"
                                    value={filter.first_name}
                                    onChange={handleFilterChange}
                                    size="small"
                                />
                                <TextField
                                    name="last_name"
                                    label="Nom"
                                    variant="outlined"
                                    value={filter.last_name}
                                    onChange={handleFilterChange}
                                    size="small"
                                />
                                <TextField
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    value={filter.email}
                                    onChange={handleFilterChange}
                                    size="small"
                                />
                                <TextField
                                    name="phone"
                                    label="Phone"
                                    variant="outlined"
                                    value={filter.phone}
                                    onChange={handleFilterChange}
                                    size="small"
                                />
                        <Button type="submit" variant="primary">Filtrer</Button> {/* Submit button */}
                        </form>
                        <Box display={'flex'} alignItems={'start'} justifyContent={'space-between'}>
                        {/* Expiration Date */}
                        <Box>
                            <Formik initialValues={initialValuesUpdateExpirationDate} validationSchema={validationSchemaUpdateExpirationDate}>
                                {({ values, setFieldValue }) => (
                        <Form>
                          <Box display="flex" flexDirection="row">
                            <Box sx={{ minWidth: "300px" }}>
                              <Field name="expiration_date" min={minDate} setFieldValue={setFieldValue} as={MyDPFullDate} size="medium" label="Changer date d'expiration" />
                              <ErrorMessage name="expiration_date" component={MzErrorText} />
                            </Box>
                            <Box>
                              <IconButton onClick={() => handleOpenUpdateExpiration()}>
                                <DoneRoundedIcon />
                              </IconButton>
                            </Box>
                          </Box>
                          {/* modal yupdate expiration */}
                          <ConfirmationModal
                            title="Mettre à jour la date d'expiration"
                            message="Êtes-vous sûr de bien vouloir mettre à jours la date d'expiration pour les cartes séléctionnées ?"
                            acceptLabel="oui"
                            refuseLabel="non"
                            open={openUpdateExpiration}
                            onClose={handleCloseUpdateExpiration}
                            acceptMethod={updateExpirationDate}
                            acceptMethodParams={values}
                          />
                        </Form>
                                )}
                            </Formik>
                        </Box>

                        </Box>
                    </Box>

                )}
            </Box>
            <Box display={'flex'} alignItems={'flex-end'} justifyContent={'space-between'}> 
                <Tabs value={tabIndex} onChange={handleTabChange}>
                    <Tab label="Customers" />
                    <Tab label="Empty Profiles" />
                </Tabs>
                {/* Add Customer */}
                {
                  tabIndex === 0 && 
                  <Box display={'flex'} gap={1}>
                    <Button sx={{ ":disabled": { backgroundColor: "gray", color: "white", border: "2px solid gray" } }} disabled={selectedIds.length === 0 ? true : false} onClick={() => handleExportData(selectedIds)} variant="primary">
                        Exporter les Données
                    </Button>
                    <Button variant="primary" startIcon={<Add/>} onClick={handleCreateCustomer}>
                        Create Customer
                    </Button>
                    <Button disabled={selectedIds.length === 0 ? true : false} sx={{ ":disabled": { backgroundColor: "gray", color: "white", border: "2px solid gray" } }} onClick={() => setOnDelete(true)} variant="third">
                        Delete Customer(s)
                    </Button>
              <ConfirmationModal title="Supprimer une/des cartes" message="Êtes-vous sûr de bien vouloir supprimer cette/ces cartes ?" acceptLabel="oui" refuseLabel="non" open={ondelite} onClose={() => setOnDelete(false)} acceptMethod={() => deleteUser()} />
                  </Box>
                }
                {tabIndex === 1 && (
                    <Box display={'flex'} alignItems={'center'} gap={2}>
                        <Button variant="primary" startIcon={<Add/>} onClick={handleCreateEmptyProfiles}>
                            Create Empty Profiles
                        </Button>
                        <Button variant="primary" onClick={handleExport}>
                            Export QR Codes
                        </Button>
                        
                    </Box>
                )}
            </Box>
            <Box sx={DataGridHeaderStyle}>
                <StripedDataGrid
                    getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "odd" : "even")}
                    sx={{ maxWidth: "100%" }}
                    rowHeight={40}
                    rowCount={totalPages}
                    rowsPerPageOptions={pageNumbers}
                    pageSize={perPage}
                    onPageSizeChange={(newPageSize) => {
                      setPerPage(newPageSize);
                      // console.log(newPageSize);
                    }}
                    onPageChange={(newPage) => handlePageChange(newPage + 1)}
                    rows={customers}
                    columns={columns}
                    checkboxSelection
                    onSelectionModelChange={handleSelectionModelChange}
                />
            </Box>
        </div>
    );
};

export default CustomersManagement;