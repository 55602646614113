import "./App.css";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";
import SharedLayoutAdmin from "./pages/admin/SharedLayoutAdmin";
import Login from "./pages/authentification/Login";
import Welcome from "./pages/admin/Welcome";
import Entreprises from "./pages/admin/Entreprises";
import Commandes from "./pages/admin/Commandes";
import CreerBGift from "./pages/admin/CreerBGift";
import Plans from "./pages/admin/Plans";
import Features from "./pages/admin/Features";
import CreateEntreprise from "./pages/admin/CreateEntreprise";
import EntrepriseDetails from "./pages/admin/EntrepriseDetails";
import CartesAbonnes from "./pages/admin/CartesAbonnes";
import CarteAbonneDetails from "./pages/admin/CarteAbonneDetails";
import Tickets from "./pages/admin/Tickets";
import TicketDetails from "./pages/admin/TicketDetails";
import AjouterUneCommande from "./pages/admin/AjouterUneCommande";
import NotFound from "./pages/errors/NotFound";
import PasswordRecovery from "./pages/authentification/PasswordRecovery";
import CommandeDetails from "./pages/admin/CommandeDetails";
import Unauthorized from "./pages/Unauthorized";
import { useEffect, useState } from "react";
import Api from "./api/Api";
import { Urls } from "./api/Urls";
import { Box } from "@mui/material";
import BCRM from "./pages/admin/BCRM";
import BATS from "./pages/admin/BATS";
import BSRM from "./pages/admin/BSRM";
import Pages from "./pages/admin/Pages";
import AddPage from "./pages/admin/AddPage";
import PageServices from "./pages/admin/PageServices";
import ServiceDetails from "./pages/admin/ServiceDetails";
import BdisplayStats from "./pages/admin/BdisplayStats";
import ImportEnterprise from "./pages/admin/ImportEnterprise";
import PagesGroupes from './pages/admin/PagesGroupes'
import CreateGroup from "./pages/admin/CreateGroup";
import ResetPassword from "./pages/authentification/ResetPassword";
import Dashboard from "./pages/admin/Dashboard";
import PagesContacts from "./pages/admin/PagesContacts";
import Customers from './pages/admin/CustomersManagement'
import CustomersDetails from './pages/admin/CustomerDetails'
import Prosumers from "./pages/admin/ProsumersManagement";
import CreateEmptyProfiles from "./pages/admin/CreateEmptyProfiles";
import CreateCustomer from "./pages/admin/CreateCustomer";
import CreateProsumer from "./pages/admin/CreateProsumer";
import ProsumerDetails from "./pages/admin/ProsumerDetails";
import ProfileDetails from './pages/admin/ProfileDetails';

function App() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
    setLoading(false);
  }, []);
  let timer;
  const logoutTime = 30 * 60 * 1000;

  // Démarre le minuteur
  function startTimer() {
    timer = setTimeout(logout, logoutTime);
  }

  function resetTimer() {
    clearTimeout(timer);
    startTimer();
  }

  function logout() {
    console.log("logging out");
    Api.post(Urls.LOGOUT, null, {
      headers: { "content-type": "application/json", authorization: `Bearer ${user?.access_token}` },
    })
      .then((response) => {
        // console.log("Response Logout", response);
        if (response.status === 200) {
          localStorage.removeItem("user");
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
        }
        // console.log(error.response.data.message);
      });
  }

  window.addEventListener("mousemove", resetTimer);

  // Démarre le minuteur au chargement de la page
  startTimer();
  return (
    <Box>
      {loading === false && (
        <Routes>
          <Route index path="*" element={<NotFound />} />
          <Route path="" element={<Navigate to="login" />} />
          <Route path="/" element={<Outlet />} />
          <Route path="mot-de-passe-oublier" element={user?.access_token ? <Navigate to="/admin" replace /> : <PasswordRecovery />} />
          <Route path="reset-password" element={user?.access_token ? <Navigate to="/admin" replace /> : <ResetPassword />} />


          <Route path="login" element={user?.access_token ? <Navigate to="/admin" replace /> : <Login />} />
          <Route path="/admin" element={user?.access_token ? <SharedLayoutAdmin /> : <Navigate to="/login" />}>
            <Route index path="/admin/dashboard" element={<Dashboard/>}/>
            <Route index element={<Welcome />} />

            {/* gestion des utilisateurs */}
            <Route path="gestion-des-entreprises">
              <Route index path="/admin/gestion-des-entreprises" element={<Entreprises />} />
              <Route path="/admin/gestion-des-entreprises/creer" element={<CreateEntreprise />} />
              <Route path="/admin/gestion-des-entreprises/:id" element={<EntrepriseDetails />} />
              <Route index path="/admin/gestion-des-entreprises/import" element={<ImportEnterprise />} />
            </Route>

            {/* Gestion des cartes abonnés */}
            <Route path="gestion-des-cartes">
              <Route index path="/admin/gestion-des-cartes" element={<CartesAbonnes />} />
              <Route path="/admin/gestion-des-cartes/:id" element={<CarteAbonneDetails />} />
            </Route>

            {/* Gestion des commandes */}
            <Route path="gestion-des-commandes">
              <Route index path="/admin/gestion-des-commandes" element={<Commandes />} />
              <Route index path="/admin/gestion-des-commandes/:id" element={<CommandeDetails />} />
              <Route index path="/admin/gestion-des-commandes/ajouter-une-commande" element={<AjouterUneCommande />} />
            </Route>

            
            {/* <Route index path="/admin/coming-soon" element={<ComingSoon />} /> */}

            {/* Service apres vente */}
            <Route path="service-apres-vente">
              <Route index path="/admin/service-apres-vente" element={<Tickets />} />
              <Route path="/admin/service-apres-vente/ticket/:id" element={<TicketDetails />} />
              {/* <Route index path="/admin/service-apres-vente" element={<ComingSoon />} />
            <Route path="/admin/service-apres-vente/tiquets/:id" element={<ComingSoon />} /> */}
            </Route>

            {/* Gestion des bgift */}
            <Route path="service-bgift">
              <Route index path="/admin/service-bgift" element={<CreerBGift />} />
            </Route>

            {/* Gestion des plans */}
            <Route path="plans">
              <Route index path="/admin/plans" element={<Plans />} />
              <Route index path="/admin/plans/features" element={<Features />} />
            </Route>
            
            {/* BDisplay */}
              <Route index path="/admin/pages" element={<Pages />} />
              <Route index path="/admin/pages/add" element={<AddPage />} />
              <Route index path="/admin/pages/:id" element={<AddPage />} />
              <Route index path="/admin/:page/services" element={<PageServices />} />
              <Route index path="/admin/page/:page/service" element={<ServiceDetails />} />{/*create*/}
              <Route index path="/admin/page/:page/service/:service" element={<ServiceDetails />} />{/*update*/}
              <Route index path="/admin/page/:page/statistics" element={<BdisplayStats />} />{/*get stats*/}
              <Route index path="/admin/pages/groups" element={<PagesGroupes />} />
              <Route index path="/admin/pages/groups/:id" element={<CreateGroup />} />
              <Route index path="/admin/pages/groups/add" element={<CreateGroup />} />

            {/* User management */}
            <Route index path="/admin/gestion-des-customers" element={<Customers />} />
            <Route path="/admin/gestion-des-customers/:id" element={<CustomersDetails />} />
            <Route index path="/admin/gestion-des-prosumers" element={<Prosumers />} />
            <Route path="/admin/gestion-des-prosumers/:id" element={<ProsumerDetails />} />
            <Route path="/admin/user/:userId/profiles/:profileId" element={<ProfileDetails />} />           
            <Route path='/admin/create-empty-profiles' element={<CreateEmptyProfiles />} />
            <Route path='/admin/create-customer' element={<CreateCustomer />} />
            <Route path='/admin/create-prosumer' element={<CreateProsumer />} />

            {/* contacts  */}
            <Route path="/admin/contacts/BCRM" element={<BCRM />} />
            <Route path="/admin/contacts/BATS" element={<BATS />} />
            <Route path="/admin/contacts/BSRM" element={<BSRM />} />
          </Route>
        </Routes>
      )}
    </Box>
  );
}

export default App;
