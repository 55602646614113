import React from "react";
import { useState, useEffect, useRef } from "react";
import { Box, TextField, Button, Typography, Modal, Autocomplete, IconButton, FormControl, InputLabel, Select,MenuItem, Stack, CircularProgress } from "@mui/material";
import { frFR } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { columns } from "../../data/cartesAbonnesData";
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import { CustomToolbar } from "../../components/dataGridStyles/CustomToolbar";
import EmptyDataGridOverlay from "../../components/ui/EmptyDataGridOverlay";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import * as Yup from "yup";
import MzErrorText from "../../components/ui/MzErrorText";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useAuthContext } from "../../hooks/useAuthContext";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import MyDPFullDate from "../../components/ui/MyDPFullDate";
import { useNavigate } from "react-router-dom";
import { colors } from "../../theme/Colors";
import { toast, ToastContainer } from "react-toastify";
import { ModalStyle } from "../../theme/ModalStyles";
import FileIcon from "../../imgs/file.svg";
import { StripedDataGrid } from "../../components/ui/StripedGrid";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import * as XLSX from "xlsx";

function CartesAbonnes() {
  const { user } = useAuthContext();
  const access_token = user.access_token;
  const role = user.user.role;

  //navigate to create enterprise
  const navigate = useNavigate();

  const status = [
    { id: "COMMANDED", name: "Commandée" },
    { id: "SUSPENDED", name: "Suspendue" },
    { id: "ACTIVE", name: "Active" },
  ];

  const userTypes =  [
    { id: 0, name: "BCARD" },
    { id: 1, name: "BGIFT" },
  ];

  const formRef = useRef();

  //add docs modal
  const [openAddDoc, setOpenAddDoc] = useState(false);
  const [docToAddName, setDocToAddName] = useState();
  const handleOpenAddDoc = () => {
    setOpenAddDoc(true);
  };
  const handleCloseAddDoc = () => setOpenAddDoc(false);

  //validation schema add document fields
  const validationSchemaAddDoc = Yup.object({
    title: Yup.string().required("Ce champ est obligatoire"),
    document: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
  });

  //initial values doc
  const initialValuesAddDoc = {
    title: "",
    user_ids: [],
    document: "",
  };

  //File config
  const FILE_SIZE = 5000 * 1024;
  //const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "image/svg+xml", "image/webp"];
  //state update solde
  const [didUpdateSolde, setDidUpdateSolde] = useState(false);
  //data grid pages configuration
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedIDs, setSelectedIDs] = useState([]);
  // const [openParams, setOpenParams] = React.useState(false);
  const pageNumbers = [10, 25, 50, 100];
  const [rowsAPI, setRowsAPI] = useState([]);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(pageNumbers[1]);

  //trigger load table on suspend or activate account
  const [didSuspend, setDidSuspend] = useState(false);
  const [didActivate, setDidActivate] = useState(false);
  const [didUpdateExpiration, setDidUpdateExpiration] = useState(false);

  //modal config
  const [openSuspend, setOpenSuspend] = useState(false);
  const [openActive, setOpenActive] = useState(false);
  const [openUpdateExpiration, setOpenUpdateExpiration] = useState(false);

  const handleOpenSuspend = () => {
    if (selectedIDs.length === 0) {
      toast.error("Aucune ligne séléctionnée");
      return;
    }
    setOpenSuspend(true);
  };
  const handleCloseSuspend = () => setOpenSuspend(false);

  const handleOpenActive = () => {
    if (selectedIDs.length === 0) {
      toast.error("Aucune ligne séléctionnée");
      return;
    }
    setOpenActive(true);
  };
  const handleCloseActive = () => setOpenActive(false);

  const handleOpenUpdateExpiration = () => {
    if (selectedIDs.length === 0) {
      toast.error("Aucune ligne séléctionnée");
      return;
    }
    setOpenUpdateExpiration(true);
  };
  const handleCloseUpdateExpiration = () => setOpenUpdateExpiration(false);

  //initialValues
  const initialValuesCartes = {
    id: "",
    collaboratedEnterpriseCommercialName: "",
    lastName: "",
    firstName: "",
    phoneNumber: "",
    department: "",
    function: "",
    expirationDateMin: "",
    expirationDateMax: "",
    email: "",
    statusCard: "",
    is_gift:""
  };
  //initialValues update expiration date
  const initialValuesUpdateExpirationDate = {
    expiration_date: "",
  };

  //validation schema update expiration date
  const validationSchemaUpdateExpirationDate = Yup.object({
    expiration_date: Yup.date(),
  });

  //validation schema
  const validationSchema = Yup.object({
    id: Yup.string(),
    collaboratedEnterpriseCommercialName: Yup.string(),
    lastName: Yup.string(),
    firstName: Yup.string(),
    phoneNumber: Yup.string(),
    department: Yup.string(),
    function: Yup.string(),
    expirationDateMin: Yup.date(),
    expirationDateMax: Yup.date(),
    email: Yup.string(),
    statusCard: Yup.string(),
    is_gift: Yup.boolean()
  });

  //page state

  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // eliminate empty data
  function nonEmptyObject(obj) {
    let tempObj = obj;
    for (const propName in tempObj) {
      if ((typeof tempObj[propName] === "object" && Object.keys(tempObj[propName]).length === 0) || tempObj[propName] === null || tempObj[propName] === "") {
        delete tempObj[propName];
      }
    }
    return tempObj;
  }

  async function getColls () {
    await Api.get(Urls.GET_COLLABS, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
      params: { perPage: perPage },
    })
      .then((response) => {
        setIsLoading(false);
        if (role === "SALE") {
          const temp = response.data.users.map(
            (item) =>
            (item = {
              ...item,
              email: "##########",
              department: "############",
              function: "############",
              phone_number: "############",
            })
          );
          setRowsAPI(temp);
        } else {
          setRowsAPI(response.data.users);
        }
        setTotal(response.data.meta.total);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
        // console.log(error);
      })
   }

  //on page load
  useEffect(() => {
    setIsLoading(true);
    getColls()
  }, []);

  //onPageChange
  useEffect(() => {
    setIsLoading(true);
        Api.get(Urls.GET_COLLABS + `?perPage=${perPage}&page=${page}`, {
          headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
          },
          params: nonEmptyObject(formRef.current.values),
        })
          .then((response) => {
            setRowsAPI(response.data.users);
            if (role == "SALE") {
              const temp = response.data.users.map(
                (item) =>
                (item = {
                  ...item,
                  email: "##########",
                  department: "############",
                  function: "############",
                  phone_number: "############",
                })
              );
              setRowsAPI(temp);
            } else {
              setRowsAPI(response.data.users);
            }
            setIsLoading(false);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              localStorage.removeItem("user");
              navigate("/login", { replace: true });
              navigate(0, { replace: true });
            }
            // console.log(error.response.message);
            if (error?.response?.status === 403) {
              toast.error("Une erreur est survenue");
            } else {
              toast.error(error?.response?.data?.message);
            }
          })
  }, [page, perPage, didActivate, didSuspend, didUpdateExpiration, didUpdateSolde]);

  // //on search
  const onSubmitSearchUsers = (values) => {
    let realValues = nonEmptyObject(values);

    setPage(0);
    setIsLoading(true);
        Api.get(Urls.GET_COLLABS + `?perPage=${perPage}`, {
          headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
          },
          params: realValues,
        })
          .then((response) => {
            // console.log("response", response);
            setIsLoading(false);

            if (role == "SALE") {
              const temp = response.data.users.map(
                (item) =>
                (item = {
                  ...item,
                  email: "##########",
                  department: "############",
                  function: "############",
                  phone_number: "############",
                })
              );
              setRowsAPI(temp);
            } else {
              setRowsAPI(response.data.users);
            }

            setTotal(response.data.meta.total);
          })
          .catch((error) => {
            // console.log(error);
            if (error.response.status === 401) {
              localStorage.removeItem("user");
              navigate("/login", { replace: true });
              navigate(0, { replace: true });
            }
            if (error?.response?.status === 403) {
              toast.error("Une erreur est survenue");
            } else {
              toast.error(error?.response?.data?.message);
            }
          })
  };

  //update solde user
  const updateSolde = (values) => {
    Api.patch(
      Urls.UPDATE_SOLDE,
      {
        user_ids: selectedIDs,
        balance: values.solde,
      },
      {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((res) => {
        toast.success(res.data.message);
        setOpenModifySolde(false);
        setDidUpdateSolde(!didUpdateSolde);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  //update card status
  const updateCardStatus = (action) => {
    Api.patch(
      Urls.UPDATE_CARD_STATUS,
      {
        user_ids: selectedIDs,
        status_card: action,
      },
      {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        // console.log("RESPONSE", response);

        if (response.status === 200) {
          // console.log(response);
          if (action === "ACTIVE") {
            toast.success("Cartes activées");
            setDidActivate(!didActivate);
          }
          if (action === "SUSPENDED") {
            toast.success("Cartes suspendues");
            setDidSuspend(!didSuspend);
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log("RESPONSE", error.response.data.message);
        toast.error(error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  //upload progress
  const [progress, setProgress] = useState();

  //add docs
  const addDocs = (values) => {
    // console.log("values", values);
    // console.log("access_token", access_token);
    Api.post(
      Urls.ADD_USER_DOCUMENT,
      {
        title: values.title,
        user_ids: selectedIDs,
        document: values.document,
      },
      {
        headers: {
          "Content-type": "multipart/form-data",
          authorization: `Bearer ${access_token}`,
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        },
      }
    )
      .then((response) => {
        // console.log("RESPONSE ADD DOCS", response);

        if (response.status === 201) {
          // console.log(response);
          handleCloseAddDoc();
          toast.success("Document ajouté avec succès");
          setDocToAddName("");
          setProgress();
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
        setDocToAddName("");
      });
  };

  

  // const [jsonToExportRealValues, setJsonToExportRealValues] = useState([]);

  //export excell data
  const handleExportData = (jsonToExport) => {
    let jsonToExportRealValues = [];

    jsonToExport.map((j) => {
      const row = { Nom: j["last_name"], Prénom: j["first_name"], "Code QR": j["url_qrcode"], "Lien du profil": `${j["url_profile"]}` };
      jsonToExportRealValues.push(row);
    });

    // console.log("REAL VALUES", jsonToExportRealValues);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(jsonToExportRealValues);

    XLSX.utils.book_append_sheet(workbook, worksheet, "data");

    XLSX.writeFile(workbook, "file.xlsx");

    jsonToExportRealValues = [];
  };

  const updateExpirationDate = (values) => {
    // console.log("UPDATE NEW DATE TO ===>", values);
    Api.patch(
      Urls.UPDATE_EXPIRATION_DATE,
      {
        user_ids: selectedIDs,
        expiration_date: values.expiration_date,
      },
      {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        // console.log("RESPONSE UPDATE EXPIRATION DATE", response);

        if (response.status === 200) {
          // console.log(response);
          toast.success("Date d'expiration des cartes mise à jour");
          setDidUpdateExpiration(!didUpdateExpiration);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  //delete a user state
  const [ondelite, setOnDelete] = useState(false);
  const [ondeliteNext, setOnDeletNext] = useState(false);
  const [motdepasse, setMotDePasse] = useState("0zFgtYmLv9");
  const minDate = new Date();
  //ref formik
  const formik = useRef();
  minDate.setDate(minDate.getDate() + 1);
  //modal modify solde
  const [openModifySolde, setOpenModifySolde] = useState(false);
  //DELETE USER
  const deleteUser = () => {
    Api.delete(Urls.DELETE_USER, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      data: {
        user_ids: selectedIDs,
        passphrase: motdepasse,
      },
    })
      .then((res) => {
        toast.success("utilisateur supprimé");
        setOnDeletNext(false);
        setDidActivate(!didActivate);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  return (
    <Box>
      {/* Header Section */}
      {/* Filter  Section */}
      {/* Form Section */}
      <Box className="filter" display="flex" flexDirection="row">
        <Formik
          innerRef={formRef}
          //initial values
          initialValues={initialValuesCartes}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmitSearchUsers(values, access_token);
            // console.log("FORM REF", formRef?.current?.values);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Box display="flex" flexDirection="column">
                <Box>
                  <Typography noWrap mr marginTop="0.45rem" mb>
                    Filtre
                  </Typography>
                </Box>

                {/*Search fields  */}

                <Box display="flex" maxWidth="900px" sx={{ flexDirection: { md: "row", xs: "column" } }}>
                  <Box flex={0.5} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth name="id" type="input" as={TextField} label="Id" size="small" />
                    <ErrorMessage name="id" as={MzErrorText} />
                  </Box>
                  <Box flex={2} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth label="Entreprise (nom commercial )" name="collaboratedEnterpriseCommercialName" as={TextField} type="input" size="small" />
                    <ErrorMessage name="collaboratedEnterpriseCommercialName" component={MzErrorText} />
                  </Box>
                  <Box flex={1} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth label="Nom" name="lastName" as={TextField} type="input" size="small" />
                    <ErrorMessage name="lastName" component={MzErrorText} />
                  </Box>
                  <Box flex={1} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth label="Prénom" name="firstName" as={TextField} type="input" size="small" />
                    <ErrorMessage name="firstName" component={MzErrorText} />
                  </Box>
                  <Box flex={1} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth label="Téléphone" name="phoneNumber" as={TextField} type="input" size="small" />
                    <ErrorMessage name="phoneNumber" component={MzErrorText} />
                  </Box>
                </Box>

                <Box display="flex" maxWidth="1400" sx={{ flexDirection: { md: "row", xs: "column" } }}>
                  <Box flex={0.6} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth label="Département" name="department" as={TextField} type="input" size="small" />
                    <ErrorMessage name="department" component={MzErrorText} />
                  </Box>
                  <Box flex={0.6} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth label="Fonction" name="function" as={TextField} type="input" size="small" />
                    <ErrorMessage name="function" component={MzErrorText} />
                  </Box>
                  <Box flex={0.6} alignItems="center" marginRight="1rem" mb>
                    <Field
                      fullWidth
                      name="statusCard"
                      component={Autocomplete}
                      options={status}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      getOptionLabel={(s) => s.name}
                      onChange={(event, value) => {
                        setFieldValue("statusCard", value ? value.id : "");
                      }}
                      renderInput={(params) => <TextField {...params} name="autocomplete" label="Statut carte" />}
                      size="small"
                    />
                    <ErrorMessage name="statusCard" component={MzErrorText} />
                  </Box>
                </Box>
                <Box display="flex" maxWidth="1400" sx={{ flexDirection: { sm: "column", md: "column", lg: "row", xs: "column" } }}>
                  <Box flex={0.8} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth label="Email" name="email" as={TextField} type="input" size="small" />
                    <ErrorMessage name="email" component={MzErrorText} />
                  </Box>
                  <Box flex={1.5} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth name="expirationDateMin" as={MyDPFullDate} setFieldValue={setFieldValue} variant="outlined" label="Date d'expiration min" size="small" />
                    <ErrorMessage name="expirationDateMin" component={MzErrorText} />
                  </Box>
                  <Box flex={1.5} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth name="expirationDateMax" as={MyDPFullDate} setFieldValue={setFieldValue} variant="outlined" label="Date d'expiration max" size="small" />
                    <ErrorMessage name="expirationDateMax" component={MzErrorText} />
                  </Box>
                  <Box flex={1.5} marginRight="1rem" mb>
                  <Field
                      fullWidth
                      name="gift"
                      component={Autocomplete}
                      options={userTypes}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      getOptionLabel={(s) => s.name}
                      onChange={(event, value) => {
                        setFieldValue("is_gift", value ? value.id : "");
                      }}
                      renderInput={(params) => <TextField {...params} name="autocomplete" label="User Type" />}
                      size="small"
                    />
                    <ErrorMessage name="gift" component={MzErrorText} />
                  </Box>
                  <Box flex={0.5} marginRight="1rem" mb>
                    <Button type="submit" variant="primary">
                      Chercher
                    </Button>
                  </Box>
                </Box>
              </Box>
              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            </Form>
          )}
        </Formik>
      </Box>
      {/* End form section */}

      {/* Actions section */}
      <Box>
        <Typography noWrap mr marginTop="0.45rem" mb>
          Actions
        </Typography>
        <Box display="flex" gap="0.5rem" flexDirection="column" /*sx={{ flexDirection: { lg: "row", xs: "column" } }}*/>
          <Box display="flex" gap="0.5rem" mb sx={{ flexDirection: { lg: "row", xs: "column", md: "row", sm: "column" } }}>
            <Button
              disabled={selectedIDs.length === 0 ? true : false}
              onClick={() => {
                handleOpenAddDoc();
              }}
              variant="primary"
              sx={{ ":disabled": { backgroundColor: "gray", color: "white", border: "2px solid gray" } }}
            >
              Ajouter un document
            </Button>
            <Button sx={{ ":disabled": { backgroundColor: "gray", color: "white", border: "2px solid gray" } }} disabled={selectedIDs.length === 0 ? true : false} onClick={handleOpenActive} variant="primary">
              Activer les cartes
            </Button>
            <Button sx={{ ":disabled": { backgroundColor: "gray", color: "white", border: "2px solid gray" } }} disabled={selectedIDs.length === 0 ? true : false} onClick={handleOpenSuspend} variant="third">
              Suspendre les cartes
            </Button>
          </Box>
          <Box display="flex" sx={{ flexDirection: { lg: "row", xs: "column", sm: "column", md: "row" } }} gap="0.5rem">
            <Box sx={{ maxWidth: 350 }}>
              <Formik initialValues={initialValuesUpdateExpirationDate} validationSchema={validationSchemaUpdateExpirationDate}>
                {({ values, setFieldValue }) => (
                  <Form>
                    <Box display="flex" flexDirection="row">
                      <Box sx={{ minWidth: "300px" }}>
                        <Field name="expiration_date" min={minDate} setFieldValue={setFieldValue} as={MyDPFullDate} size="medium" label="Changer date d'expiration" />
                        <ErrorMessage name="expiration_date" component={MzErrorText} />
                      </Box>
                      <Box>
                        <IconButton onClick={() => handleOpenUpdateExpiration()}>
                          <DoneRoundedIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    {/* modal yupdate expiration */}
                    <ConfirmationModal
                      title="Mettre à jour la date d'expiration"
                      message="Êtes-vous sûr de bien vouloir mettre à jours la date d'expiration pour les cartes séléctionnées ?"
                      acceptLabel="oui"
                      refuseLabel="non"
                      open={openUpdateExpiration}
                      onClose={handleCloseUpdateExpiration}
                      acceptMethod={updateExpirationDate}
                      acceptMethodParams={values}
                    />
                  </Form>
                )}
              </Formik>
            </Box>
            <Button sx={{ ":disabled": { backgroundColor: "gray", color: "white", border: "2px solid gray" } }} disabled={selectedRows.length === 0 ? true : false} onClick={() => handleExportData(selectedRows)} variant="primary">
              Exporter les données
            </Button>
          </Box>
        </Box>
        <ToastContainer style={{ top: "5rem" }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
      </Box>
      {/* Modal add DOC */}
      <Modal open={openAddDoc} onClose={handleCloseAddDoc}>
        <Box sx={ModalStyle}>
          <Typography fontSize="1.5rem" fontWeight="600" mb="2rem">
            Ajouter un document
          </Typography>
          {/* form add doc */}
          <Formik initialValues={initialValuesAddDoc} validationSchema={validationSchemaAddDoc} onSubmit={addDocs}>
            {({ values, setFieldValue, errors }) => (
              <Form>
                {/* doc title */}
                <Box className="field" sx={{ marginBottom: "1rem" }}>
                  <Field name="title" type="input" label="Titre" id="title" as={TextField} fullWidth size="small" />
                  <ErrorMessage name="title" component={MzErrorText} />
                </Box>
                {progress && (
                  <Stack margin="1rem 0rem" gap="0.5rem" direction="row" sx={{ alignItems: "center", justifyContent: "center" }}>
                    <Typography color={colors.main_purple}>{progress ? progress + "%" : null}</Typography>
                    <CircularProgress sx={{ color: `${colors.main_purple}` }} />
                  </Stack>
                )}
                {/* doc file */}
                <Box display="flex" flexDirection="column" sx={{ marginBottom: "1rem" }}>
                  <Button variant="upload" sx={{ height: "200px", display: "flex", flexDirection: "column" }} component="label">
                    <img src={FileIcon} width="50px" alt="" style={{ marginBottom: "0.5rem" }} />
                    <input
                      style={{ opacity: "0%", width: "100%", height: "100%", position: "absolute" }}
                      id="document"
                      name="document"
                      type="file"
                      // accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={(event) => {
                        const doc = event.target.files[0];
                        setFieldValue("document", event.target.files[0]);
                        setDocToAddName(doc.name);
                      }}
                    />
                    <Typography sx={{ fontSize: "1rem", fontWeight: "400" }}>Déposer votre fichier</Typography>
                  </Button>
                  <MzErrorText>{errors.document}</MzErrorText>
                </Box>
                {docToAddName && (
                  <Box>
                    <Typography sx={{ margin: "1rem 0rem", color: `${colors.main_purple}`, fontWeight: "500", textDecoration: "underline" }} fontSize="0.875rem">
                      {docToAddName}
                    </Typography>
                  </Box>
                )}

                {/* confirm add doc */}
                <Box display="flex" flexDirection="row" gap="0.5rem">
                  <Button variant="primary" type="submit">
                    Ajouter
                  </Button>
                  <Button onClick={handleCloseAddDoc} variant="third">
                    Annuler
                  </Button>
                </Box>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              </Form>
            )}
          </Formik>
          {/* end form add DOC */}
        </Box>
      </Modal>
      <Modal open={openModifySolde} onClose={() => setOpenModifySolde(false)}>
        <Box sx={ModalStyle}>
          <Typography fontSize="1.5rem" fontWeight="600" mb="2rem">
            Modifier le solde
          </Typography>
          {/* form add doc */}
          <Formik
            innerRef={formik}
            initialValues={{
              solde: "",
            }}
            validationSchema={Yup.object().shape({
              solde: Yup.number().typeError("entrer une valeur valide").min(0, "entrer une valeur valide").required("ce champ est obligatoire"),
            })}
            onSubmit={() => {
              updateSolde(formik.current.values);
            }}
          >
            {({ values, setFieldValue, errors, touched }) => (
              <Form>
                <Box marginBottom="1rem" display="flex" flexDirection="column" gap="0.5rem">
                  <label>Entrer le nouveau solde :</label>
                  <Field name="solde" type="imput" label="Solde" id="Solde" as={TextField} fullWidth size="small" />
                  {errors.solde && touched.solde && <MzErrorText>{errors.solde}</MzErrorText>}
                </Box>
                <Box display="flex" alignItems="center" gap="1rem" justifyContent="center">
                  <Button type="submit" variant="primary">
                    Modifier
                  </Button>
                  <Button onClick={() => setOpenModifySolde(false)} variant="secondary">
                    Annuler
                  </Button>
                </Box>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              </Form>
            )}
          </Formik>
          {/* end form add DOC */}
        </Box>
      </Modal>

      <ConfirmationModal title="Suspendre les cartes" message="Êtes-vous sûr de bien vouloir suspendre les cartes séléctionnées ?" acceptLabel="oui" refuseLabel="non" open={openSuspend} onClose={handleCloseSuspend} acceptMethod={updateCardStatus} acceptMethodParams="SUSPENDED" />
      <ConfirmationModal title="Activer les cartes" message="Êtes-vous sûr de bien vouloir activer les cartes séléctionnées ?" acceptLabel="oui" refuseLabel="non" open={openActive} onClose={handleCloseActive} acceptMethod={updateCardStatus} acceptMethodParams="ACTIVE" />

      {/* Data grid section */}
      {/* Data grid container (styles imported from DataGridHeaderContainer) */}
      {/* <pre>page number : {JSON.stringify(page, null, 2)}</pre>
      <pre>per page : {JSON.stringify(perPage, null, 2)}</pre> */}
      <Box marginTop="1rem">
        <Button
          disabled={selectedIDs.length === 0 ? true : false}
          onClick={() => {
            setOpenModifySolde(true);
          }}
          variant="primary"
          sx={{ ":disabled": { backgroundColor: "gray", color: "white", border: "2px solid gray" } }}
        >
          Modifier le solde
        </Button>
      </Box>
      <Box marginTop="1rem">
        <Button disabled={selectedIDs.length === 0 ? true : false} sx={{ ":disabled": { backgroundColor: "gray", color: "white", border: "2px solid gray" } }} onClick={() => setOnDelete(true)} variant="third">
          Supprimer une/des carte(s)
        </Button>
      </Box>
      <ConfirmationModal title="Supprimer une/des cartes" message="Êtes-vous sûr de bien vouloir supprimer cette/ces cartes ?" acceptLabel="oui" refuseLabel="non" open={ondelite} onClose={() => setOnDelete(false)} acceptMethod={() => setOnDeletNext(true)} />
      <Modal open={ondeliteNext} onClose={() => setOnDeletNext(false)}>
        <Box sx={ModalStyle}>
          <Typography fontSize="1.5rem" fontWeight="600" mb>
            Supprimer une/des carte(s)
          </Typography>
          <Typography mb="1rem">Entrez votre mot de passe</Typography>
          <Box marginBottom="1rem">
            <TextField type="password" value={motdepasse} onChange={(e) => setMotDePasse(e.target.value)} name="motdepasse" placeholder="Mot de passe" />
          </Box>
          <Box display="flex" flexDirection="row" gap="0.5rem">
            <Button
              variant="primary"
              onClick={() => {
                deleteUser();
              }}
            >
              Supprimer
            </Button>
            <Button onClick={() => setOnDeletNext(false)} variant="third">
              Annuler
            </Button>
          </Box>
        </Box>
      </Modal>
      <Box sx={DataGridHeaderStyle}>
        <StripedDataGrid
          //stripes
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "odd" : "even")}
          sx={{ maxWidth: "100%" }}
          rowHeight={40}
          onSelectionModelChange={(ids) => {
            const selection = new Set(ids);
            setSelectedIDs(ids);
            const selectedRows = rowsAPI.filter((row) => selection.has(row.id));
            setSelectedRows(selectedRows);
          }}
          loading={isLoading}
          disableSelectionOnClick
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyDataGridOverlay,
          }}
          columns={columns}
          //data to feed datagrid
          rows={rowsAPI}
          //allow selection
          checkboxSelection
          //how many rows in a page ?
          onPageSizeChange={(newPageSize) => {
            setPerPage(newPageSize);
            // console.log(newPageSize);
          }}
          pageSize={perPage}
          rowsPerPageOptions={pageNumbers}
          //mode
          paginationMode="server"
          //total number of rows per search
          rowCount={total}
          //onpage change
          onPageChange={(newPage) => {
            // console.log(newPage);
            setPage(newPage);
          }}
          pagination
          page={page}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
    </Box>
  );
}

export default CartesAbonnes;
